<template>
  <div class="protect w">
    <div class="imgs">
      <div class="manage-btn">
        <span>智慧管理</span>
      </div>
      <img src="../assets/imgs/guanlixian.png" alt="" />
      <div class="manage-small">
        <span class="manage-small1">
          <em>展藏品</em>
          <em>智慧管理</em>
        </span>
        <span class="manage-small1"><em>数字资源</em> <em>智慧管理</em></span>
        <span class="manage-small1"><em>工作人员</em> <em>智慧管理</em></span>
        <span class="manage-small1"><em>观众行为</em> <em>智慧管理</em></span>
        <span class="manage-small1"><em>票务</em> <em>智慧管理</em></span>
        <span class="manage-small1">...</span>
      </div>
    </div>
    <img class="w" src="../assets/imgs/guangli3.png" alt="" />
    <div class="case-study">
      <div class="text-title">
        <span>项目案例</span>
        <p>
          提供全面的博物馆馆内文物的预防性保护方案及措施，以及提供相应的环境质量监测
          设备、环境调控设施、文物保存设施
        </p>
      </div>
      <div class="text-content">
        <div class="text-content-box">
          <div class="list-img" v-for="(item, index) of manageDataStat" :key="index">
            <div class="imgs-box">
              <!-- <img :src="baseIP + imageShowAPI + item.imageUri" alt="" /> -->
              <!-- <img :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" /> -->
              <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
              <img v-else :src="defaultImage" alt="" />
            </div>
            <div class="describe">{{ item.title }}</div>
          </div>
          <!-- <div class="list-img">
            <div class="imgs-box">
              <img src="../assets/imgs/case01.png" alt="" />
            </div>
            <div class="describe">文物数字化采集</div>
          </div>
          <div class="list-img">
            <div class="imgs-box">
              <img src="../assets/imgs/case01.png" alt="" />
            </div>
            <div class="describe">文物数字化采集</div>
          </div> -->
        </div>
        <div class="btn">
          <div>
            <em class="el-icon-arrow-down"></em>
            <span @click="moreData">查看更多</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getColumnContent } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        manageData: [],
        manageDataStat: [],
        defaultImage: require('../assets/imgs/enterpriseDefault.jpg'),
      };
    },
    created() {
      this.getColumnContentManage();
    },
    methods: {
      // 获取智慧管理数据
      getColumnContentManage() {
        getColumnContent({ optionType: '0' }).then((res) => {
          if (res.code === 0) {
            this.manageData = res.data.content;
            this.manageDataStat = this.manageData.slice(0, 3);
          }
        });
      },
      // 点击更多数据
      moreData() {
        const length = this.manageData.length;
        if (length > this.manageDataStat.length) {
          this.manageDataStat = this.manageData.slice(
            0,
            this.manageDataStat.length + 3
          );
        } else {
          this.$message({
            type: "warning",
            message: "没有更多数据了",
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .protect {
    .imgs {
      margin-bottom: 80px;
      .manage-btn {
        width: 100%;
        height: 64px;
        line-height: 64px;
        margin-top: 70px;
        span {
          display: block;
          width: 142px;
          height: 64px;
          margin: 0 auto;
          text-align: center;
          line-height: 64px;
          background-color: #f3f3f3;
          border-radius: 4px;
          color: #333333;
          font-size: 28px;
        }
      }
      .manage-small {
        width: 100%;
        height: 64px;
        line-height: 64px;
        .manage-small1 {
          display: inline-block;
          width: 142px;
          height: 64px;
          margin: 0 auto;
          line-height: 64px;
          text-align: center;
          font-size: 18px;
          background-color: #f3f3f3;
          color: #333333;
          border-radius: 4px;
          float: left;
          box-sizing: border-box;
          padding-top: 10px;
          em {
            font-style: normal;
            display: block;
            line-height: 20px;
            margin-bottom: 5px;
          }
        }
        .manage-small1:nth-child(1) {
          margin-left: 105px;
          margin-right: 75px;
        }
        .manage-small1:nth-child(2) {
          margin-right: 60px;
        }
        .manage-small1:nth-child(3) {
          margin-right: 80px;
        }
        .manage-small1:nth-child(4) {
          margin-right: 60px;
        }
        .manage-small1:nth-child(5) {
          margin-right: 60px;
        }
        .manage-small1:nth-child(6) {
          padding-top: 0;
        }
      }
      img {
        display: block;
        width: 100%;
        height: 60px;
      }
    }
    .case-study {
      margin-top: 100px;
      .text-title {
        width: 665px;
        line-height: 60px;
        margin: 0 auto;
        margin-bottom: 20px;
        span {
          display: inline-block;
          height: 40px;
          line-height: 40px;
          font-size: 34px;
          color: #333333;
        }
        p {
          line-height: 30px;
          font-size: 18px;
          color: #999999;
          margin: 10px 0;
        }
      }
      .text-content {
        // height: 454px;
        .text-content-box {
          display: flex;
          justify-content: left;
          align-items: center;
          flex-wrap: wrap;
          .list-img {
            width: 430px;
            height: 325px;
            line-height: 325px;
            cursor: pointer;
            margin: 15px;

            .imgs-box {
              width: 430px;
              height: 276px;
              overflow: hidden;
              transition: all 0.9s;
              img {
                display: block;
                width: 100%;
                height: 276px;
                transition: all 0.9s;
              }
            }
            img:hover {
              transform: scale(1.3);
            }
            .describe {
              height: 50px;
              line-height: 50px;
              text-align: center;
              font-size: 18px;
              font-weight: bold;
              color: #666666;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 10px;
              text-align: left;
            }
          }
          .list-img:hover {
            box-shadow: 0px 5px 10px 0px rgba(56, 56, 56, 0.13);
            .describe {
              color: #0f6ebe;
            }
          }
        }
      }
      .btn {
        width: 100%;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn > div {
        height: 20px;
        line-height: 20px;
        cursor: pointer;
        color: #999999;
        font-size: 20px;
        em {
          margin-right: 10px;
        }
      }
    }
  }
</style>