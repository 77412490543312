<template>
  <div>
    <div class="protect w">
      <div class="imgs">
        <div class="manage-btn">
          <span>智慧保护</span>
        </div>
        <img src="../assets/imgs/guanlixian.png" alt="" />
        <div class="manage-small">
          <span class="manage-small1"><em>数字化</em><em>预防保护</em></span>
          <span class="manage-small1"><em>文物本体</em> <em>健康评测</em></span>
          <span class="manage-small1"><em>文物保护</em> <em>环境感知</em></span>
          <span class="manage-small1"><em>风险动态</em> <em>智能预警</em></span>
          <span class="manage-small1"><em>文物修复</em> <em>信息保护</em></span>
          <span class="manage-small1">...</span>
        </div>
      </div>
      <img class="w" src="../assets/imgs/baohu03.png" alt="" />
      <div class="case-study">
        <div class="text-title">
          <span>数字化保护</span>
          <p>
            古建筑航空遥感测绘、地面三维扫描、纹理采集；高精度三维模型制作；
            绘制建筑制图
          </p>
        </div>
        <div class="text-content">
          <div class="text-content-box">
            <img src="../assets/imgs/Protectimg.jpg" alt="">
            <!-- <div class="list-img1">
              <div class="img-box radius">
                <img src="../assets/imgs/caseImg.png" alt="" />
              </div>
              <div class="describe">地面三维扫描</div>
            </div>
            <em class="el-icon-plus"></em>
            <div class="list-img1">
              <div class="img-box radius">
                <img src="../assets/imgs/case01.png" alt="" />
              </div>
              <div class="describe">地空高清纹理采集</div>
            </div>
            <em class="el-icon-plus"></em>
            <div class="list-img1">
              <div class="img-box radius">
                <img src="../assets/imgs/case01.png" alt="" />
              </div>
              <div class="describe">空中LIDAR测绘</div>
            </div>
            <div class="list-img distance">
              <div class="img-box">
                <img src="../assets/imgs/Digitizing01.png" alt="" />
              </div>
              <div class="describe">高精度古建筑三维模型</div>
            </div>
            <em class="el-icon-back" style="color: #bd1a21"></em>
            <div class="list-img distance">
              <div class="img-box">
                <img src="../assets/imgs/Digitizing02.png" alt="" />
              </div>
              <div class="describe">纹理贴图</div>
            </div>
            <em class="el-icon-back" style="color: #bd1a21"></em>
            <div class="list-img distance">
              <div class="img-box">
                <img src="../assets/imgs/Digitizing03.png" alt="" />
              </div>
              <div class="describe">三维建模</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="prevention">
      <div class="content w">
        <div class="text-title">
          <span>预防性保护</span>
          <p>
            提供全面的博物馆馆内文物的预防性保护方案及措施，以及提供相应的环境
            质量监测设备、环境调控设施、文物保存设施
          </p>
        </div>
        <div class="preventions">
          <div class="prevention-left" v-for="item of prevention" :key="item.id">
            <!-- <img :src="baseIP + imageShowAPI + item.imageUri" alt="" /> -->
            <!-- <img :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" /> -->
            <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
            <img v-else :src="defaultImage" alt="" />
            <div class="title">{{ item.title }}</div>
          </div>
          <div class="prevention-left" v-if="prevention.length==1">
            <img src='../assets/imgs/enterpriseDefault.jpg' alt="" />
            <div class="title">广州昱安信息技术有限公司</div>
          </div>
          <div v-if="prevention.length==0" class="prevention-left-box">
            <div class="prevention-left">
              <img src='../assets/imgs/enterpriseDefault.jpg' alt="" />
              <div class="title">广州昱安信息技术有限公司</div>
            </div>
            <div class="prevention-left">
              <img src='../assets/imgs/enterpriseDefault.jpg' alt="" />
              <div class="title">广州昱安信息技术有限公司</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="features w">
      <div class="text-title">
        <span>产品案例</span>
        <p>
          提供全面的博物馆馆内文物的预防性保护方案及措施，以及提供相应的环境质量监测
          设备、环境调控设施、文物保存设施
        </p>
      </div>
      <div class="features-img">
        <div class="features-list-img" v-for="(item, index) of caseDataStat" :key="index">
          <div class="features-list-img-box" @click="productJump(item.secondColumnsName, item.id)">
            <img :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
          </div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
      <div class="btn" @click="moreData">
        <em class="el-icon-arrow-down"></em>
        查看更多
      </div>
    </div>
  </div>
</template>

<script>
  import { getColumnContent } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        caseData: [], // 案例数据
        caseDataStat: [],
        casehardwareData: [],
        prevention: [], // 预防性数据
        defaultImage: require('../assets/imgs/enterpriseDefault.jpg'),
      };
    },
    created() {
      //软件产品和硬件产品数据
      this.getData();
      this.getColumnContentPrevention("1");
    },
    methods: {
      // 产品中心跳转
      productJump(name, ids) {
        console.log(name, 'name')
        if (name === "软件产品" && name != null) {
          this.$router.push({
            name: "software",
            query: { num: "software", id: ids },
          });
        } else if (name === "硬件产品" && name != null) {
          this.$router.push({
            name: "hardware",
            query: { num: "hardware", id: ids },
          });
        } else {
          this.$message({
            type: "error",
            message: "数据错误",
          });
        }
      },
      getData() {
        //软件产品和硬件产品数据
        this.getColumnContentSoftware("9");
        this.getColumnContentHardware("10");
      },
      // 软件产品数据
      getColumnContentSoftware(val) {
        getColumnContent({ optionType: val }).then((res) => {
          if (res.code === 0) {
            this.caseData.push(...res.data.content);
          }
        });
      },
      // 硬件产品数据
      getColumnContentHardware(val) {
        getColumnContent({ optionType: val }).then((res) => {
          if (res.code === 0) {
            this.caseData.push(...res.data.content);
            setTimeout(() => {
              this.caseData &&
                this.caseDataStat.push(...this.caseData.slice(0, 6));
            }, 300);
          }
        });
      },
      // 智慧保护数据
      getColumnContentPrevention(val) {
        getColumnContent({ optionType: val }).then((res) => {
          if (res.code === 0) {
            this.prevention = res.data.content.slice(0, 2);
          }
        });
      },
      // 更多数据
      moreData() {
        const length = this.caseData.length;
        if (length > this.caseDataStat.length) {
          this.caseDataStat = this.caseData.slice(
            0,
            this.caseDataStat.length + 6
          );
        } else {
          this.$message({
            type: "warning",
            message: "没有更多数据了",
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .protect {
    .imgs {
      margin-bottom: 80px;
      .manage-btn {
        width: 100%;
        height: 64px;
        line-height: 64px;
        margin-top: 70px;
        span {
          display: block;
          width: 142px;
          height: 64px;
          margin: 0 auto;
          text-align: center;
          line-height: 64px;
          background-color: #f3f3f3;
          border-radius: 4px;
          color: #333333;
          font-size: 28px;
        }
      }
      .manage-small {
        width: 100%;
        height: 64px;
        line-height: 64px;
        .manage-small1 {
          display: inline-block;
          width: 142px;
          height: 64px;
          margin: 0 auto;
          line-height: 64px;
          text-align: center;
          font-size: 18px;
          background-color: #f3f3f3;
          color: #333333;
          border-radius: 4px;
          float: left;
          box-sizing: border-box;
          padding-top: 10px;
          em {
            font-style: normal;
            display: block;
            line-height: 20px;
            margin-bottom: 5px;
          }
        }
        .manage-small1:nth-child(1) {
          margin-left: 105px;
          margin-right: 75px;
        }
        .manage-small1:nth-child(2) {
          margin-right: 60px;
        }
        .manage-small1:nth-child(3) {
          margin-right: 80px;
        }
        .manage-small1:nth-child(4) {
          margin-right: 60px;
        }
        .manage-small1:nth-child(5) {
          margin-right: 60px;
        }
        .manage-small1:nth-child(6) {
          padding-top: 0;
        }
      }
      img {
        display: block;
        width: 100%;
        height: 60px;
      }
    }
    .case-study {
      .text-title {
        width: 665px;
        // height: 120px;
        line-height: 40px;
        margin: 0 auto;
        margin-bottom: 30px;
        span {
          display: inline-block;
          height: 40px;
          line-height: 40px;
          font-size: 36px;
          color: #333333;
        }
        p {
          line-height: 30px;
          font-size: 18px;
          color: #999999;
          margin: 10px 0;
          letter-spacing: 1px;
        }
      }
      .text-content {
        .text-content-box {
          // width: 1400px;
          // height: 953px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .prevention {
      // height: 760px;
      background: url('../assets/imgs/prevention.png') no-repeat;
      background-size: cover;
      .content {
        // height: 750px;
        padding-top: 60px;
        box-sizing: border-box;
        padding-bottom: 50px;
        .text-title {
          width: 665px;
          // height: 120px;
          line-height: 40px;
          margin: 0 auto;
          // margin-bottom: 20px;
          span {
            display: inline-block;
            height: 40px;
            line-height: 40px;
            font-size: 34px;
            color: #ffffff;
          }
          p {
            line-height: 30px;
            font-size: 18px;
            color: #ffffff;
            margin: 10px 0;
            letter-spacing: 1px;
          }
        }
        .preventions {
          margin-top: 60px;
          display: flex;
          justify-content: left;
          align-items: center;
          .prevention-left {
            width: 690px;
            height: 389px;
            margin-right: 15px;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            img {
              display: block;
              width: 100%;
              height: 100%;
              transition: all 0.9s;
            }
            img:hover {
              transform: scale(1.3);
            }
            .title {
              width: 100%;
              height: 55px;
              line-height: 55px;
              text-align: left;
              padding-left: 35px;
              color: #fff;
              font-size: 22px;
              position: absolute;
              bottom: 0;
              background: rgba(0, 0, 0, 0.5);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          .prevention-left-box {
            display: flex;
            justify-content: left;
            align-items: center;
          }
          .prevention-right {
            width: 690px;
            height: 389px;
            position: relative;
            cursor: pointer;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
            .title {
              width: 100%;
              height: 55px;
              line-height: 55px;
              text-align: left;
              padding-left: 10px;
              color: #ffffff;
              font-size: 22px;
              position: absolute;
              bottom: 0;
              background: rgba(0, 0, 0, 0.5);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .features {
      padding-top: 100px;
      .text-title {
        width: 665px;
        // height: 120px;
        line-height: 40px;
        margin: 0 auto;
        margin-bottom: 70px;
        span {
          display: inline-block;
          height: 40px;
          line-height: 40px;
          font-size: 34px;
          color: #000;
        }
        p {
          line-height: 30px;
          font-size: 18px;
          color: #999999;
          margin: 20px 0;
          letter-spacing: 1px;
        }
      }
      .features-img {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
        .features-list-img {
          width: 445px;
          height: 290px;
          margin: 0 21px 20px 0;
          cursor: pointer;
          .features-list-img-box {
            // width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 233px;
            overflow: hidden;
            background-color: #f1f1f1;
            img {
              display: block;
              // width: 100%;
              // height: 100%;
              max-width: 100%;
              max-height: 100%;
              transition: all 0.9s;
            }
            img:hover {
              transform: scale(1.3);
            }
          }
          .title {
            height: 57px;
            line-height: 57px;
            text-align: center;
            background-color: #999999;
            color: #ffffff;
            font-family: '微软雅黑';
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 10px;
          }
        }
        .features-list-img:hover {
          .title {
            background-color: #0280d4;
          }
        }
        // .features-list-img:nth-child(1) {
        //   margin-bottom: 30px;
        // }
        // .features-list-img:nth-child(2) {
        //   margin-bottom: 30px;
        // }
        // .features-list-img:nth-child(3) {
        //   margin-bottom: 30px;
        // }
      }
    }
    .btn {
      // width: 130px;
      height: 40px;
      line-height: 40px;
      margin: 0 auto;
      color: #999999;
      font-size: 20px;
      cursor: pointer;
      margin-top: 50px;
      margin-bottom: 100px;
      em {
        margin-right: 5px;
      }
    }
  }
</style>