<template>
  <div class="protect w">
    <div class="imgs">
      <div class="manage-btn">
        <span>智慧服务</span>
      </div>
      <img src="../assets/imgs/line.png" alt="" />
      <div class="manage-small">
        <span class="manage-small1"><em>观众自助</em><em>票务预约系统</em></span>
        <span class="manage-small1"><em>智能咨询</em></span>
        <span class="manage-small1"><em>多媒体</em> <em>数字导览</em></span>
        <span class="manage-small1"><em>互动展览</em></span>
        <span class="manage-small1"><em>新媒体推送</em></span>
        <span class="manage-small1"><em>信息推送</em></span>
        <span class="manage-small1">...</span>
      </div>
    </div>
    <div class="case-study">
      <div class="case-list" v-for="item of serviceDataStat" :key="item.id">
        <div class="imgs-box">
          <!-- <img :src="baseIP+imageShowAPI+item.imageUri" alt=""> -->
          <!-- <img :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" /> -->
          <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
          <img v-else :src="defaultImage" alt="" />
        </div>
        <div class="case-text">
          <h4>{{item.title}}</h4>
          <p v-html="item.columnsContent"></p>
        </div>
      </div>
    </div>
    <div class="more">
      <em class="el-icon-arrow-down"></em>
      <span @click="moreData">查看更多</span>
    </div>
  </div>
</template>

<script>
  import { getColumnContent } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        serviceData: [],
        defaultImage: require('../assets/imgs/enterpriseDefault.jpg'),
        serviceDataStat: []
      }
    },
    created() {
      getColumnContent({ optionType: '2' }).then((res) => {
        if (res.code === 0) {
          console.log(res, '智慧服务')
          this.serviceData = res.data.content
          this.serviceDataStat = this.serviceData.slice(0, 6)
        }
      })

    },
    methods: {
      moreData() {
        const length = this.serviceData.length
        if (length > this.serviceDataStat.length) {
          this.serviceDataStat = this.serviceData.slice(0, this.serviceDataStat.length + 3)
        } else {
          this.$message({
            type: 'warning',
            message: '没有更多数据了'
          })
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .protect {
    .imgs {
      margin-bottom: 80px;
      .manage-btn {
        width: 100%;
        height: 64px;
        line-height: 64px;
        margin-top: 70px;
        span {
          display: block;
          width: 142px;
          height: 64px;
          margin: 0 auto;
          text-align: center;
          line-height: 64px;
          background-color: #f3f3f3;
          border-radius: 4px;
          color: #0280d4;
          font-size: 28px;
        }
      }
      .manage-small {
        width: 100%;
        height: 64px;
        line-height: 64px;
        .manage-small1 {
          display: inline-block;
          width: 142px;
          height: 64px;
          margin: 0 auto;
          line-height: 64px;
          text-align: center;
          font-size: 18px;
          background-color: #f3f3f3;
          color: #0280d4;
          border-radius: 4px;
          float: left;
          box-sizing: border-box;
          padding-top: 10px;
          em {
            font-style: normal;
            display: block;
            line-height: 20px;
            margin-bottom: 5px;
          }
        }
        .manage-small1:nth-child(1) {
          margin-left: 45px;
          margin-right: 50px;
          em:nth-child(2) {
            color: #333333;
          }
        }
        .manage-small1:nth-child(2) {
          margin-right: 55px;
          padding-top: 20px;
        }
        .manage-small1:nth-child(3) {
          margin-right: 50px;
          em:nth-child(2) {
            color: #333333;
          }
        }
        .manage-small1:nth-child(4) {
          margin-right: 60px;
          padding-top: 20px;
        }
        .manage-small1:nth-child(5) {
          margin-right: 50px;
          padding-top: 20px;
        }
        .manage-small1:nth-child(6) {
          margin-right: 60px;
          padding-top: 20px;
        }
        .manage-small1:nth-child(7) {
          padding-top: 0;
        }
      }
      img {
        display: block;
        width: 100%;
        height: 60px;
      }
    }
    .case-study {
      height: 100%;
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      .case-list {
        margin-right: 25px;
        box-sizing: border-box;
        border-bottom: 7px solid #fff;
        cursor: pointer;
        .imgs-box {
          width: 435px;
          height: 276px;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 276px;
            transition: all 0.9s;
          }
        }
        img:hover {
          transform: scale(1.2);
        }

        .case-text {
          width: 435px;
          height: 124px;
          line-height: 124px;
          box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 25px;
          background-color: #f2f3f5;
          margin-top: -6px;
          h4 {
            height: 40px;
            line-height: 40px;
            font-size: 20px;
            text-align: left;
            color: #333333;
            padding-top: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p {
            line-height: 25px;
            font-size: 16px;
            color: #999999;
            text-align: left;
            margin-top: 5px;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
      .case-list:hover {
        border-bottom: 7px solid #008aff;
        background-color: #fafafa;
        .case-text {
          background-color: #fafafa;
        }
      }
      .case-list:nth-child(1) {
        margin-bottom: 10px;
      }
      .case-list:nth-child(2) {
        margin-bottom: 10px;
      }
      .case-list:nth-child(3) {
        margin-bottom: 10px;
      }
    }
    .more {
      margin: 50px 0;
      em {
        font-size: 20px;
      }
      span {
        font-size: 20px;
        margin-left: 10px;
        color: #999999;
        cursor: pointer;
      }
    }
  }
</style>