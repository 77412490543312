<template>
  <div class="manage">
    <!-- 顶部图片 -->
    <div class="imgs">
      <img src="../../assets/imgs/banner1o3.jpg" alt="">
    </div>
    <div class="nav">
      <ul class="w">
         <li @click="addNum(0,'smartManagement')" :class="{ active: num === 'smartManagement' }">智慧管理</li>
        <li @click="addNum(1,'smartProtection')" :class="{ active: num === 'smartProtection' }">智慧保护</li>
        <li @click="addNum(2,'smartService')" :class="{ active: num === 'smartService' }">智慧服务</li>
      </ul>
    </div>
    <div class="content">
      <div class="protect">
        <Protect></Protect>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";
import Manage from "@/components/Manage";
import Protect from "@/components/Protect";

export default {
  data() {
    return {
      num: '',
    };
  },
  mounted () {
      this.$store.commit('ip/SET_routefullPath',this.$route.fullPath)
      this.num = this.$route.query.num
  },
  methods: {
    addNum(i,val){
        this.num = val
        this.$router.push({name:val,query:{num:val}})
    }   
  },
  components: {
    Carousel,
    Manage,
    Protect,
  },
};
</script>

<style lang="scss" scoped>
.imgs{
  width: 100%;
  img{
    width: 100%;
    height: 100%;
    vertical-align: bottom;
  }
}
.nav {
  height: 63px;
  line-height: 60px;
  background-color: #fbfbfb;
  border: solid 1px #ededed;
  margin-bottom: 10px;
 ul {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    li {
      width: 100px;
      font-size: 20px;
      color: #666666;
      box-sizing: border-box;
      border-bottom: 3px solid #fff;
      cursor: pointer;
    }
    li:nth-child(2) {
      margin: 0 50px;
    }
    .active {
      border-bottom-color: #e43838;
      color: #333333;
    }
  }
}
.content {
  .protect {
    margin-bottom: 10px;
  }
  .service {
    margin-bottom: 10px;
  }
  .manage {
    margin-bottom: 10px;
  }
}
</style>