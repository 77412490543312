<template>
  <div class="banner">
    <el-carousel :interval="5000" arrow="always">
      <el-carousel-item v-for="(item, index) in banners" :key="index">
        <img
          :src="baseIP+imageShowAPI+item.imageUri"
          @click="jump(item.linkAddress)"
          alt=""
          class="imgs"
          style="display: inline-block;cursor: pointer;"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import store from "@/store";
import { getBanner } from '@/api/index.js'
export default {
  // props: {
  //   bannerData: {
  //     type: Array,
  //   },
  // },
  data() {
    return {
      baseIP: process.env.VUE_APP_BASE_URL,
      imageShowAPI: "/yuan/files/getFileStream/",
      banners: [],
    };
  },
  created () {
    this.getBanner()
  },
  methods: {
    // 获取轮播图数据
    getBanner(){
       getBanner().then((res)=>{
      if(res.code === 0){
        this.banners = res.data.content
      }
    })
    },
    jump(linkAddress){
      if(linkAddress){
        window.open(linkAddress)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .banner-image {
    width: 100%;
    height: 100%;
  }
  /deep/ .el-carousel__container {
    height: 687px;
  }
}
/deep/ .el-carousel__arrow--left {
  left: 240px;
  width: 60px;
  height: 60px;
  .el-icon-arrow-left{
    font-size: 28px;
  }
}
 ::v-deep .imgs {
  width: 100%;
  height: 100%;
  // min-height: 887px;
}
/deep/ .el-carousel__arrow--right {
  right: 240px;
  width: 60px;
  height: 60px;
  .el-icon-arrow-right{
      font-size: 28px;
  }
}
</style>